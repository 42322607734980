




















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Tests extends Vue {
  private openTestWibsiteHandler() {
    window.open("https://demo-data-mav.itway.company:8010/", "_blank");
  }
}
